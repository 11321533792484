import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { z } from "zod";

import { workplaceProfileSchema } from "./types";

const getWorkplaceProfileQuery = z.object({
  workplaceId: z.string(),
  projection: z.record(z.string(), z.number()).optional(),
});

const getWorkplaceProfileResponse = workplaceProfileSchema;

export type GetWorkplaceProfileQuery = z.infer<typeof getWorkplaceProfileQuery>;
export type WorkplaceProfileResponse = z.infer<typeof workplaceProfileSchema>;

function getWorkplaceProfileUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/${workplaceId}`;
}

// TODO(ModalWorkplaceData): convert response to ModalWorkplaceData
export function useGetWorkplaceProfile(
  queryParams: GetWorkplaceProfileQuery,
  options: UseGetQueryOptions<WorkplaceProfileResponse> = {}
) {
  const { workplaceId, ...workplaceProfileRequestParams } = queryParams;
  return useGetQuery({
    url: getWorkplaceProfileUrl(workplaceId),
    queryParams: {
      projection: {
        ...workplaceProfileRequestParams.projection,
        geoLocation: 1,
        maxAllowedWorkConsecutiveHours: 1,
        maxAllowedWorkHoursPerWeek: 1,
        phone: 1,
        requiresLunchBreak: 1,
        requiresLunchBreakSince: 1,
        providesRestBreaks: 1,
        providesRestBreaksSince: 1,
        sentHomeChargeHours: 1,
        tmz: 1,
        type: 1,
        fullAddress: 1,
      },
    },
    requestSchema: getWorkplaceProfileQuery.omit({ workplaceId: true }),
    responseSchema: getWorkplaceProfileResponse,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_PROFILE_FAILURE,
    },
    enabled: !!workplaceId,
    ...options,
  });
}
