import { IconWithCount } from "@clipboard-health/ui-components";

interface WorkplaceReviewCommentRepliesCountIconProps {
  count: number;
}

export function WorkplaceReviewCommentRepliesCountIcon(
  props: WorkplaceReviewCommentRepliesCountIconProps
) {
  const { count } = props;

  return (
    <IconWithCount
      iconType="text-bubble"
      count={count}
      color={(theme) => theme.palette.text.tertiary}
    />
  );
}
