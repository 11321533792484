import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuestions } from "@src/appV2/Reviews/api/useGetQuestions";
import { useGetWorkplaceWorkerShifts } from "@src/appV2/Reviews/api/useGetWorkplaceWorkerShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";

interface WorkplaceReviewsFooterProps {
  workplaceId: string;
  workplaceType?: string;
}

export function WorkplaceReviewsFooter(props: WorkplaceReviewsFooterProps) {
  const { workplaceId, workplaceType } = props;

  const { userId: workerId } = useDefinedWorker();

  const { data: shifts = [] } = useGetWorkplaceWorkerShifts({
    workerId,
    workplaceId,
  });

  const { data: questions = { data: [] } } = useGetQuestions(
    {
      workplaceType: workplaceType ?? "",
      workplaceId,
    },
    { enabled: isDefined(workplaceType) }
  );

  const { generateModalPath } = useWorkplaceModalsDataContext();

  if (shifts.length === 0 || questions.data.length === 0) {
    return null;
  }

  return (
    <DialogFooter withShadow orientation="horizontal">
      <Button
        fullWidth
        variant="outlined"
        size="large"
        href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH, {
          workplaceId,
        })}
      >
        Leave a Review
      </Button>
    </DialogFooter>
  );
}
