import { IconWithCountButton } from "@clipboard-health/ui-components";
import { useDeleteCommentReaction } from "@src/appV2/Reviews/api/useDeleteCommentReaction";
import { usePostCommentReaction } from "@src/appV2/Reviews/api/usePostCommentReaction";
import { type WorkplaceCommentAttributesType } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { Reaction } from "@src/appV2/Reviews/types";

interface WorkplaceReviewCommentLikesButtonProps {
  isLiked: boolean;
  count: number;
  onLikeChanged: (reaction: WorkplaceCommentAttributesType["workerReaction"]) => void;
  workplaceId: string;
  commentId: string;
}

export function WorkplaceReviewCommentLikesButton(props: WorkplaceReviewCommentLikesButtonProps) {
  const { isLiked, count, onLikeChanged, workplaceId, commentId } = props;

  const { mutate: postCommentReaction } = usePostCommentReaction(
    { workplaceId, commentId },
    {
      onSuccess: () => {
        onLikeChanged(Reaction.LIKE);
      },
    }
  );

  const { mutate: deleteCommentReaction } = useDeleteCommentReaction(
    { workplaceId, commentId },
    {
      onSuccess: () => {
        onLikeChanged(null);
      },
    }
  );

  return (
    <IconWithCountButton
      isActive={isLiked}
      count={count}
      baseIconType="like-outlined"
      activeIconType="like-filled"
      onClick={(event) => {
        event.stopPropagation();

        if (isLiked) {
          deleteCommentReaction();
        } else {
          postCommentReaction({ reaction: Reaction.LIKE });
        }
      }}
    />
  );
}
