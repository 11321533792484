import { Card } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import { type WorkplaceCommentType } from "@src/appV2/Reviews/api/useWorkplaceComments";
import { parseISO } from "date-fns";

import { WorkplaceReviewCommentAuthorInfo } from "./CommentAuthorInfo";

interface WorkplaceReviewCommentCompactCardProps {
  comment: WorkplaceCommentType;
}

export function WorkplaceReviewCommentCompactCard(props: WorkplaceReviewCommentCompactCardProps) {
  const { comment } = props;
  const { createdAt, workerInfo, text } = comment.attributes;
  const { id: workerId, name: workerName } = workerInfo;

  return (
    <Card outlined variant="tertiary">
      <CardContent
        sx={{
          padding: 7,
          height: "100%",
          minHeight: "11rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Text variant="body2" sx={{ paddingBottom: 6 }}>
          {text}
        </Text>

        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <WorkplaceReviewCommentAuthorInfo
            workerName={workerName}
            workerId={workerId}
            createdAt={parseISO(createdAt)}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
