import type { UseGetQueryOptions } from "@src/appV2/api";
import { type GeoLocation } from "@src/appV2/Location";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";

import {
  type CalculateDistanceResponse,
  useGetDrivingDistance,
} from "../GeoLocation/useGetDrivingDistance";

interface UseDrivingDistanceToWorkplaceProps extends UseGetQueryOptions<CalculateDistanceResponse> {
  workplaceGeoLocation?: GeoLocation;
  workerGeoLocation?: GeoLocation;
}

export function useDrivingDistanceToWorkplace(props: UseDrivingDistanceToWorkplaceProps) {
  const {
    workplaceGeoLocation,
    workerGeoLocation: workerOriginGeoLocation,
    ...queryOptions
  } = props;

  const workerGeoLocation = useWorkerGeoLocation();

  const origin = workerOriginGeoLocation ?? workerGeoLocation;
  const destination = workplaceGeoLocation
    ? { latitude: workplaceGeoLocation.latitude, longitude: workplaceGeoLocation.longitude }
    : undefined;

  const { data, ...rest } = useGetDrivingDistance(
    {
      origin,
      destination,
    },
    queryOptions
  );

  return {
    ...rest,
    data,
    // Because the query can be disabled in case worker or facility geoLocation is not defined,
    // we need to use always the isInitialLoading instead of isLoading.
    // Otherwise, it will be loading forever even if the query is disabled.
    // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
    isLoading: rest.isInitialLoading,
  };
}
