import { isDefined } from "@clipboard-health/util-ts";
import { calculateEstimatedDrivingDistanceInMiles, type GeoLocation } from "@src/appV2/Location";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { round } from "lodash";

interface UseDistanceToWorkplaceProps {
  workplaceGeoLocation?: GeoLocation;
  workerGeoLocation?: GeoLocation;
}

export function formatDistance(distanceInMiles: number): string {
  if (distanceInMiles < 1) {
    return "<1 mi";
  }

  // Using lodash to round to 1 decimal place, i.e. "1.2 mi" instead of "1.23 mi".
  // Math.round() would round to the nearest integer, i.e. "1 mi" instead of "1.2 mi".
  return `${round(distanceInMiles, 1)} mi`;
}

/**
 * @returns "1.2 mi" or "<1 mi"
 */
export function useDistanceToWorkplace(props: UseDistanceToWorkplaceProps) {
  const { workplaceGeoLocation, workerGeoLocation: workerOriginGeoLocation } = props;

  const workerGeoLocation = useWorkerGeoLocation();

  const origin = workerOriginGeoLocation ?? workerGeoLocation;
  const destination = workplaceGeoLocation;

  if (!isDefined(origin) || !isDefined(destination)) {
    return { formattedDistance: undefined, distanceInMiles: undefined };
  }

  const rawDistance = calculateEstimatedDrivingDistanceInMiles(origin, destination);

  return { formattedDistance: formatDistance(rawDistance), distanceInMiles: rawDistance };
}
