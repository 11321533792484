import { workplace as workplaceSchemaBFF } from "@clipboard-health/contract-worker-app-bff";
import { facilitySchema } from "@src/appV2/Facilities/types";
import { type z } from "zod";

// This corresponds to the response from useGetWorkplaces
// https://github.com/ClipboardHealth/worker-app-bff/blob/main/src/modules/workplaces/logic/workplaces.mapper.ts
export const workplaceSchema = workplaceSchemaBFF.pick({ type: true, id: true }).extend({
  attributes: workplaceSchemaBFF.shape.attributes.pick({
    location: true,
    rating: true,
    name: true,
  }),
});

export type Workplace = z.infer<typeof workplaceSchema>;

// Picking only data relevant to the response from useGetWorkplaceProfile
// TODO: replace with the new schema once endpoint for getting workplace profile is implemented
export const workplaceProfileSchema = facilitySchema.pick({
  email: true,
  fullAddress: true,
  geoLocation: true,
  lateCancellation: true,
  maxAllowedWorkConsecutiveHours: true,
  maxAllowedWorkHoursPerWeek: true,
  name: true,
  phone: true,
  requiresLunchBreak: true,
  requiresLunchBreakSince: true,
  providesRestBreaks: true,
  providesRestBreaksSince: true,
  sentHomeChargeHours: true,
  tmz: true,
  type: true,
  userId: true,
});

// TODO(ModalWorkplaceData): replace with ModalWorkplaceData
export type WorkplaceProfile = z.infer<typeof workplaceProfileSchema>;

export interface WorkplaceLocationState {
  origin?: "WORKPLACE_PROFILE_MODAL" | "WORKPLACE_OPEN_SHIFTS_MODAL";
}
